

























import _3did from "@/api/3did";
import { DataQuery } from "@/api/models/dataQuery";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TableRow extends Vue {
  @Prop({ default: "" }) date!: string;
  @Prop({ default: "" }) contact!: string;
  @Prop({ default: "" }) username!: string;
  @Prop({ default: "" }) scanId!: string;
  @Prop({ default: [] }) dataQueries!: DataQuery[];

  private api = new _3did();

  private downloading: boolean = false;

  async downloadData(query: string) {
    if (document.activeElement instanceof HTMLElement)
      document.activeElement.blur();

    this.downloading = true;

    const prefix = this.contact + "_" + this.formatDateForFile(this.date) + "-";

    try {
      var resp = await this.api.data(this.scanId, query, prefix);

      if ('output' in resp)
        if ('url' in resp.output)
          window.location.assign(resp.output.url);
    } finally {
      this.downloading = false;
    }
  }

  formatDate(date: string): string {
    let options: Intl.DateTimeFormatOptions = {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(date).toLocaleDateString("en-us", options);
  }

  formatDateForFile(dateString: string): string {
    const date = new Date(dateString);

    return [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getDate().toString().padStart(2, "0"),
      (date.getHours() % 12 ? date.getHours() % 12 : 12)
        .toString()
        .padStart(2, "0"),
      date.getMinutes().toString().padStart(2, "0"),
      date.getSeconds().toString().padStart(2, "0"),
      date.getHours() < 12 ? "AM" : "PM",
    ].join("_");
  }
}
