import { App, PagedApps } from "./models/app";
import { PagedUsers } from "./models/user";
import { PagedScans } from "./models/scan";
import DataUrl from "./models/data";
import { DataQuery, PagedDataQueries } from "./models/dataQuery";

import { API } from "aws-amplify";

export default class ThreeDIdApi {
  protected api: string = "3did";

  public async apps() {
    let apps: App[] = [];
    let continueFrom: any = true;
    while (continueFrom) {
      let response: PagedApps = await API.get(this.api, "/apps", {
        headers: {
          paginate: "true"
        },
        ...(typeof continueFrom === "string") && {
          queryStringParameters: {
            startAfter: continueFrom
          }
        }
      });
      apps.push(...response.applications);
      continueFrom = response.continueFrom;
    }
    return apps;
  }

  public async dataQueries(appId: string) {
    let dataQueries: DataQuery[] = [];
    let continueFrom: any = true;
    while (continueFrom) {
      let response: PagedDataQueries = await API.get(this.api, `/apps/${appId}/dataQueries`, {
        headers: {
          paginate: "true"
        },
        ...(typeof continueFrom === "string") && {
          queryStringParameters: {
            startAfter: continueFrom
          }
        }
      });
      dataQueries.push(...response.dataQueries);
      continueFrom = response.continueFrom;
    }
    return dataQueries;
  }

  public async *users() {
    let continueFrom: any = true;
    while (continueFrom) {
      let response: PagedUsers = await API.get(this.api, "/users", {
        headers: {
          paginate: "true"
        },
        ...(typeof continueFrom === "string") && {
          queryStringParameters: {
            startAfter: continueFrom
          }
        }
      });
      yield* response.users;
      continueFrom = response.continueFrom;
    }
  }

  public async *scans(userId: string) {
    let continueFrom: any = true;
    while (continueFrom) {
      let response: PagedScans = await API.get(this.api, `/users/${userId}/scans`, {
        headers: {
          paginate: "true"
        },
        ...(typeof continueFrom === "string") && {
          queryStringParameters: {
            startAfter: continueFrom
          }
        }
      });
      yield* response.scans;
      continueFrom = response.continueFrom;
    }
  }

  public async data(scanId: string, query: string, prefix = ""): Promise<DataUrl> {
    return await API.get(this.api, `/scans/${scanId}/data/${query}`, {
      headers: {
        "file-prefix": prefix
      }
    });
  }
}
