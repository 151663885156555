































































import _3did from "@/api/3did";
import { DataQuery } from "@/api/models/dataQuery";
import { Component, Vue } from "vue-property-decorator";

import TableRow from "../components/TableRow.vue";

interface TableItem {
  date: string;
  contact: string;
  username: string;
  scanId: string;
}

@Component({
  components: {
    "table-row": TableRow
  }
})
export default class Home extends Vue {
  private api = new _3did();

  items: TableItem[] = [];
  dataQueries: DataQuery[] = [];
  loading = false;
  sortByColumn: string = "date";
  sortAsc = false;

  async refresh() {
    this.loading = true;
    this.items = [];

    for await (let user of this.api.users()) {
      for await (let scan of this.api.scans(user.id)) {
        if (scan.status.startsWith("complete")) {
          this.items.push({
            date: scan.date,
            contact: user.email || user.phone || user.id,
            username: `${user.firstName || ""} ${user.middleName ||
              ""} ${user.lastName || ""}`,
            scanId: scan.id
          });
        }
      }
    }

    this.loading = false;
  }

  async mounted() {
    let app = (await this.api.apps())[0];
    if (app) this.dataQueries = await this.api.dataQueries(app.id);

    this.refresh();
  }

  get sortedItems() {
    if (this.sortByColumn == "date") {
      let items = this.items.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
      if (!this.sortAsc) {
        items = items.reverse();
      }
      return items;
    } else if (this.sortByColumn == "contact") {
      let items = this.items.sort((a, b) => {
        if (a.contact < b.contact) {
          return -1;
        }
        if (a.contact > b.contact) {
          return 1;
        }
        return 0;
      });
      if (!this.sortAsc) {
        items = items.reverse();
      }
      return items;
    } else {
      return this.items;
    }
  }

  sortBy(col: string) {
    if (this.sortByColumn == col) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = false;
    }
    this.sortByColumn = col;
  }
}
